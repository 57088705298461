import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"

const banner_title  = ['Opportunities ', 3000, 'don’t happen, ', 2000, 'you create them. ',1500]



const privacyPolicy = () => { 

    return (
<Layout>
<Seo title="Our Privacy policy " />
<Banner className = "service" title = "" sticky_first = "Privacy Policy" sticky_second  = "" />

<section className = "full_details">
<div className="container">
<p>Estore Company LLC and its affiliates (“Estore” or “we”) respect your concerns about privacy.
     This Website Privacy Notice describes the types of information we collect about individuals on  
      <Link to ="https://estorecompany.com "> Estorecompany </Link>(the “Site”), how we use the information The Website Privacy Notice also describes the information we collect and how long we retain it, and how individuals can contact us about our privacy practices and to exercise their rights. 
 </p>

 <h2>Information We Obtain</h2>

<h4>Information You Provide</h4>
<p>
We may collect and store personal information (such as name and other contact details) that you choose to provide to us through the Site or when you contact us. “Personal Information” is information that identifies you as an individual or relates to an identifiable individual. The types of personal information we collect may include: 
 
 <ul>
 <li>1. Contact information, such as your name, e-mail address, and telephone number; </li>
  
 <li>2. Other personal information you may submit to us, such as when you contact us. </li>
 </ul>
  
 Information We Receive About You from Other Sources 
  
 We may receive your Personal Information from other sources, such as publicly available databases and providers of demographic information.
</p>

<h2>Cookies</h2>

<p>
Depending on how you use our Site, we will store cookies on your computer in order to collect certain aggregate data about our users and to customize certain aspects of your specific user experience. A cookie is a small data text file which is stored on your computer that uniquely identifies your browser. Cookies may also include more personalized information, such as your IP address, browser type, the server your computer is logged onto, the area code and zip code associated with your server, and your first name to welcome you back to our Site. We may use cookies to perform tasks such as: monitoring aggregate site usage metrics, storing and remembering your passwords (if you allow us to do so), storing account and advertising preferences that you have set, and personalizing the Services we make available to you.  
</p>

<h2>Behavioral Advertising & Third-party Analytics services  </h2>
<p>
As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For example: 
 
 We use third-party online analytics services, such as Bing, Facebook  and Google Analytics. The service providers that administer these analytics services use cookies and similar technologies to collect and analyze information about use of the Site and report on activities and trends and diagnose technical issues.  
 
 You can opt out of targeted advertising by: 
 
 
 <ul>
<li> <Link to =" https://www.facebook.com/settings/?tab=ads">FACEBOOK</Link> </li>
<li> <Link to ="https://www.google.com/settings/ads/anonymous">GOOGLE </Link> </li>
<li> <Link to ="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">BING </Link> </li>

 </ul>

 
 Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal 
 at: <Link to ="https://optout.aboutads.info/">https://optout.aboutads.info</Link>
</p>

<h2>How We Use Personal Information</h2>
<p>
We use the Personal Information we obtain on the Site for legitimate business purposes, including to: 
 
 <ul>
     <li>1. Provide our services to you; process, evaluate, and respond to your requests and inquiries; and send administrative information to you, such as changes to our terms, conditions, and policies. We will engage in these activities to manage our contractual relationship with you and/or to comply with a legal obligation. 
 </li>
     <li>2. Market our services to you. We will engage in this activity with your consent or where we have a legitimate interest. 
  </li>
     <li>3. Operate, evaluate, and improve our business (including developing new services; enhancing and improving our services; analyzing our services; managing our communications; performing data analytics and market research; and performing accounting, auditing, and other internal functions). We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest. 
  </li>
     <li>4. Protect against, identify, and prevent fraud, security breaches, and other criminal activity and risks. We will engage in these activities to comply with a legal obligation and/or because we have a legitimate interest. 
 </li>
     <li>5. Aggregate and/or anonymize Personal Information so that it will no longer be considered personal information. We do so to generate other data for our use, which we may use and disclose for any purpose. 
  </li>
 </ul>
  
 
</p>

<h2>Personal Information Sharing</h2>
<p>Our service providers who perform services on our behalf, such as hosting this Site or data analytics. We do not authorize our service providers to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements.</p>

<p>
We also may disclose information about you 
<ul>
<li>(1) if we are required to do so by law or legal process (such as a court order or subpoena), including laws outside your country of residence; </li>
<li>(2) in response to requests by, or cooperate with, government agencies, such as law enforcement authorities, including outside of your country of residence; </li>
<li>(3) to establish, exercise, or defend our legal rights; </li>
<li>(4) when we believe disclosure is necessary or appropriate to protect our rights, privacy, safety, or property and/or that of our affiliates, you, or others; </li>
<li>(5) in connection with an investigation of suspected or actual illegal activity; </li>
<li>(6) in connection with the sale or transfer of all or a portion of our business, assets, or stock (including in the event of a reorganization, dissolution, or liquidation); or </li>
<li>(7) otherwise with your consent.</li>
</ul>
</p>

<h2>Your Rights and Choices</h2>
<p>
We offer you certain choices in connection with the personal information we obtain about you. If you would like to request to review, correct, update, suppress, restrict, or delete personal information, to object to the processing of personal information, or to receive an electronic copy of your personal information for purposes of transmitting it to another company (to the extent these rights are provided to you by applicable law), please contact us as specified in the How to Contact Us section of this Website Privacy Notice. We will respond to your request consistent with applicable law. 

</p>
<p>
To help protect your privacy and maintain security, we may take steps to verify your identity before granting you access to your information. To the extent permitted by applicable law, a charge may apply before we provide you with a copy of any of your personal information that we maintain.
</p>
<p>
You can unsubscribe from any marketing mailing lists we may maintain by following the “Unsubscribe” link in our marketing emails. If you would like to unsubscribe from any other type of marketing you receive from us, please contact us as specified in the How to Contact Us section of this Website Privacy Notice. 

</p>
<h2>Other Online Services and Third-Party Features</h2>
<p>
The Site may provide links to other online services and websites for your convenience and information, and may include third-party features such as apps, tools, widgets, and plug-ins (e.g., LinkedIn, Instagram, Facebook, and Twitter buttons). These services and websites may operate independently from us. The privacy practices of these third parties, including details on the information they may collect about you, are subject to their own privacy statements. To the extent any linked online services or third-party features are not owned or controlled by us, Estore Company is not responsible for these third parties’ information or other practices.
</p>

<h2>Retention of Personal Information</h2>
<p>
We retain personal information for as long as needed or permitted in light of the purposes for which we obtained it and consistent with applicable law. The criteria used to determine our retention periods include:
</p>
<ul>
    <li>
    1. The length of time we have an ongoing relationship with you and provide the Site or our services to you; 
 

    </li>
    <li>
    2. Whether there is a legal obligation to which we are subject; and 
  
  
    </li>
    <li>
    3. Whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitation, litigation, or regulatory investigations). 
 
    </li>
</ul>
<h2>How We Protect Personal Information</h2>
<p>We seek to use reasonable administrative, technical and physical safeguards designed to protect personal information within our organization. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed. As we have no control or ability to monitor the security of your device, you should take measures reasonably available to secure your device. In the event that Personal information is compromised as a result of a breach of security, Estore Company will promptly notify those customers on provided contact information. </p>

<h2>Minor </h2>
<p>You must be at least 18 years old to have our permission to use this Site. By agreeing to Our Policy, you are hereby confirming that you are at least of 18 years old. Our policy is that we do not knowingly collect, use or disclose Personal information about minor visitors.</p>

<h2>CCPA</h2>
<p>If you are a resident of California, you have the right to access the Personal Information we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us as specified in the How to Contact Us section of this Website Privacy Notice.</p>
<h2>GDPR</h2>

<p>If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. Please contact us as specified in the How to Contact Us section of this Website Privacy Notice. </p>

<h2>Updates to Our Website Privacy Notice</h2>

<p>This Website Privacy Notice may be updated periodically and without prior notice to you to reflect changes in our personal information practices. We will indicate at the top of the notice when it was most recently updated. Any changes will become effective when we post the revised Website Privacy Notice on the Site. 
</p>

<h2>How to Contact Us</h2>
<p>
To contact us in relation to this Privacy Notice or any privacy matter, including our use of your personal information or to exercise other applicable privacy rights, please use the following contact details: 
 
 <h4>ATTN: Privacy Policy</h4>  
 
 <a href="mailto:Info@EstoreCompany.com">Info@EstoreCompany.com</a>
  
 
 <h4>Updated: 01/15/2022 </h4>
</p>
</div>

</section>


</Layout>


    )


}


export default privacyPolicy